import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BookType } from 'src/app/modules/models/book-type';
import { environment as env } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BookTypeService {
  private baseUrl = `${env.apiHost}:${env.apiPort}/api/book_types`;

  constructor(private hc: HttpClient) { }

  getList(): Observable<BookType[]> {
    let session = window.localStorage.getItem('_session');
    if (! session) {
      session = 'loggedout';
    }
    return this.hc.get<BookType[]>(`${this.baseUrl}`, {
      headers : {
        's-t': session
      }
    });
  }

  saveNew(type: BookType): Observable<BookType> {
    let session = window.localStorage.getItem('_session');
    if (! session) {
      session = 'loggedout';
    }
    return this.hc.post<BookType>(this.baseUrl, type, {
      headers : {
        's-t': session
      }
    });
  }

  update(type: BookType): Observable<BookType> {
    let session = window.localStorage.getItem('_session');
    if (! session) {
      session = 'loggedout';
    }
    return this.hc.put<BookType>(`${this.baseUrl}/${type._id}`, type, {
      headers : {
        's-t': session
      }
    });
  }

  single(id: string): Observable<BookType> {
    let session = window.localStorage.getItem('_session');
    if (! session) {
      session = 'loggedout';
    }
    return this.hc.get<BookType>(`${this.baseUrl}/${id}`, {
      headers : {
        's-t': session
      }
    });
  }

  delete(id: string): Observable<boolean> {
    let session = window.localStorage.getItem('_session');
    if (! session) {
      session = 'loggedout';
    }
    const self = this;
    return new Observable(observer => {
      self.hc.delete(`${this.baseUrl}/${id}`, {
        headers : {
          's-t': session
        },
        observe: 'response',
        responseType: 'text'
      }).subscribe((res: HttpResponse<any>) => {
        if (res.status === 200) {
          observer.next(true);
        } else {
          observer.error(res.status);
        }
        return {unsubscribe() {}};
      });
    });
  }

}
