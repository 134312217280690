import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { User } from 'src/app/modules/models/user';
import { SetPass } from 'src/app/modules/models/set-pass';
import { environment as env } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserServiceService {

  private baseUrl = `${env.apiHost}:${env.apiPort}/api/users`;
  constructor(private hc: HttpClient) { }

  getList(): Observable<User[]> {
    let session = window.localStorage.getItem('_session');
    if (! session) {
      session = 'loggedout';
    }
    return this.hc.get<User[]>(this.baseUrl, {
      headers: {
        's-t': session
      }
    });
  }

  single(email: string): Observable<User> {
    let session = window.localStorage.getItem('_session');
    if (! session) {
      session = 'loggedout';
    }
    return this.hc.get<User>(`${this.baseUrl}/${email}`, {
      headers: {
        's-t': session
      }
    });
  }

  saveNew(user: User): Observable<User> {
    let session = window.localStorage.getItem('_session');
    if (! session) {
      session = 'loggedout';
    }
    return this.hc.post<User>(this.baseUrl, user, {
      headers : {
        's-t': session
      }
    });
  }

  update(user: User): Observable<User> {
    let session = window.localStorage.getItem('_session');
    if (! session) {
      session = 'loggedout';
    }
    return this.hc.put<User>(`${this.baseUrl}/${user.email}`, user, {
      headers : {
        's-t': session
      }
    });
  }

  setpass(sp: SetPass): Observable<User> {
    let session = window.localStorage.getItem('_session');
    if (! session) {
      session = 'loggedout';
    }
    return this.hc.put<User>(`${this.baseUrl}/${sp.email}`, sp, {
      headers : {
        's-t': session
      }
    });
  }

  delete(email: string): Observable<boolean> {
    let session = window.localStorage.getItem('_session');
    if (! session) {
      session = 'loggedout';
    }
    const self = this;
    return new Observable(observer => {
      self.hc.delete(`${this.baseUrl}/${email}`, {
        headers : {
          's-t': session
        },
        observe: 'response',
        responseType: 'text'
      }).subscribe((res: HttpResponse<any>) => {
        if (res.status === 204) {
          observer.next(true);
        } else {
          observer.error(res.status);
        }
        return {unsubscribe() {}};
      });
    });
  }

  requestNewPass(email: string): Observable<boolean> {
    let session = window.localStorage.getItem('_session');
    if (! session) {
      session = 'loggedout';
    }
    return new Observable((observer) => {
      this.hc.post(`${this.baseUrl}/setpass/${email}`, {}, {
        headers: {
          's-t': session
        },
        observe: 'response',
        responseType: 'text'
      }).subscribe((res: HttpResponse<any>) => {
        if (res.status === 200) {
          observer.next(true);
        } else {
          observer.error(res.status);
        }
        return {unsubscribe() {}};
      });
    });
  }
}
